<template>
  <div class="login flex">
    <div>
      <h1 class="login-title">
        Bienvenue sur l'interface dépôts de Florimat !
      </h1>
      <div class="mb5">
        Veuillez entrez vos identifiants pour vous connecter.
      </div>
      <div class="login-box">
        <icon-logo css-class="login__logo" />
        <form
          action="#"
          @submit.prevent="submit"
        >
          <div>
            <label for="email">Identifiant</label>
            <input
              id="email"
              v-model="form.email"
              type="text"
              name="email"
            >
          </div>
          <div>
            <label for="password">Mot de passe</label>
            <input
              id="password"
              v-model="form.password"
              type="password"
              name="password"
            >
          </div>
          <div>
            <loading-button
              type="submit"
              class="btn btn--primary mt3"
              :loading="loading"
            >
              Me connecter
            </loading-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingButton from '@/components/ui/LoadingButton.vue'
import IconLogo from '@/components/svg/Logo.vue'

export default {
  name: 'Login',
  components: {
    LoadingButton,
    IconLogo
  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      flexLayout: true,
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'authenticated'
    ])
  },
  created () {
    const hasAuthCookie = document.cookie.indexOf('XSRF-TOKEN') !== -1
    if (hasAuthCookie && this.authenticated) {
      this.$router.replace({ name: 'Catalog' })
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      fetchCategories: 'catalog/fetchCategories',
      fetchProducts: 'catalog/fetchProducts',
      fetchCart: 'cart/fetchCart'
    }),

    async submit () {
      try {
        this.loading = true
        await this.signIn(this.form)
        // les categories doivent être récupérées avant d'aller sur la page catalogue
        // pour selectionner la 1er catégorie
        await this.fetchCategories()
        await this.fetchProducts()
        await this.fetchCart()
        this.loading = false
        this.$router.replace({ name: 'Catalog' })
      } catch (error) {
        this.loading = false
        if (error.name === 'ValidationError') {
          this.$notyf.error('Impossible de se connecter. Merci de vérifier vos identifiants ou de nous contacter directement.')
        } else {
          console.error(error)
        }
      }
    }
  }
}

</script>
