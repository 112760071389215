<template>
  <back class="mb2" />
  <h1 class="title-1">
    Historique des commandes
  </h1>

  <table class="table table-half">
    <thead>
      <tr>
        <th>
          Date de commande
        </th>
        <th>
          Numéro de commande
        </th>
        <th>
          Montant
        </th>
        <th />
      </tr>
    </thead>
    <tbody class="t-overable">
      <tr
        v-for="order in orders"
        :key="order.id"
      >
        <td>{{ order.date }}</td>
        <td>{{ order.number }}</td>
        <td>{{ order.total }}</td>
        <td>
          <router-link
            :to="{name: 'OrderDetail', params: {orderId: order.id}}"
            class="btn btn--ghost"
          >
            Détails
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from '@/components/misc/Back.vue'

export default {
  name: 'OrderHistory',
  components: {
    Back
  },
  computed: {
    ...mapState({
      orders: state => state.order.orders
    })
  },
  created () {
    this.fetchOrders()
  },
  methods: {
    ...mapActions({
      fetchOrders: 'order/fetchOrders'
    })
  }
}
</script>
