// Depth-First Search
export function dfs (roots, action) {
  const stack = []
  for (const root of roots) {
    stack.push(null)
    stack.push(root)
  }
  const path = []
  while (stack.length > 0) {
    const node = stack.pop()
    if (node === null) {
      path.pop()
    } else {
      path.push(node)
      action(node, path)
      if (node.children.length) {
        for (const child of node.children) {
          stack.push(null)
          stack.push(child)
        }
      }
    }
  }
}
