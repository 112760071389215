<template>
  <td class="text-center">
    {{ cartProduct.gencode }}
  </td>
  <td>{{ cartProduct.name }}</td>
  <td>
    <input
      v-model="comment"
      class="f-small w100"
      maxlength="80"
      type="text"
      @change="updateComment"
    >
  </td>
  <td class="text-center">
    {{ cartProduct.packaging ?? "-" }}
  </td>
  <td>
    <input
      v-model="quantity"
      class="f-small w100"
      type="number"
      :step="packaging"
      min="0"
      @change="updateQuantity"
    >
  </td>
  <td class="text-right">
    {{ cartProduct.unitPrice }}
  </td>
  <td class="text-right">
    {{ total }}
  </td>
  <td>
    <button
      class="btn-delete"
      @click="remove"
    >
      <icon-delete />
    </button>
  </td>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconDelete from '@/components/svg/Delete'
import debounce from 'debounce'

export default {
  name: 'ProductRow',
  components: {
    IconDelete
  },
  props: {
    cartProduct: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      comment: null,
      packaging: 1,
      rollbackQuantity: null
    }
  },
  computed: {
    ...mapGetters('cart', [
      'productQuantities',
      'comments'
    ]),
    ...mapGetters('catalog', [
      'getProductById'
    ]),
    cartQuantity () {
      return this.productQuantities[this.cartProduct.product_reference_id + '-' + this.cartProduct.category_id] || null
    },
    cartComment () {
      return this.comments[this.cartProduct.product_reference_id + '-' + this.cartProduct.category_id] || null
    },
    total () {
      return this.cartProduct.total === null ? 'Définis lors de la facturation' : this.cartProduct.total
    },
    quantity: {
      get () {
        return this.cartQuantity
      },
      set (quantity) {
        this.setProductQuantity({ productId: this.cartProduct.product_reference_id + '-' + this.cartProduct.category_id, quantity: quantity })
      }
    }
  },
  created () {
    this.quantity = this.cartQuantity
    this.rollbackQuantity = this.quantity
    this.comment = this.cartComment
    const product = this.getProductById(this.cartProduct.product_reference_id)
    this.packaging = product?.packaging || 1
  },
  methods: {
    ...mapActions({
      addProduct: 'cart/addProduct',
      removeProduct: 'cart/removeProduct',
      editComment: 'cart/editComment',
      setProductQuantity: 'cart/setProductQuantity'
    }),
    updateQuantity: debounce(function () {
      this.quantity = Math.ceil(this.quantity / this.packaging) * this.packaging
      this.addProduct([this.cartProduct.product_reference_id, this.quantity, this.cartProduct.category_id])
        .then(() => {
          this.rollbackQuantity = this.quantity
        })
        .catch((error) => {
          this.$notyf.open({
            type: error.name === 'ValidationError' ? 'warning' : 'error',
            message: error.message
          })

          this.quantity = this.rollbackQuantity
        })
    }, 500),
    updateComment () {
      this.editComment([this.cartProduct.product_reference_id + '-' + this.cartProduct.category_id, this.comment])
    },
    remove () {
      this.removeProduct([this.cartProduct.product_reference_id, this.cartProduct.category_id])
    }
  }
}
</script>
