<template>
  <router-link
    :to="route"
    class="back"
  >
    <icon-arrow-left css-class="back__icon" /> Revenir au catalogue
  </router-link>
</template>

<script>
import IconArrowLeft from '@/components/svg/ArrowLeft.vue'
export default {
  name: 'Back',
  components: {
    IconArrowLeft
  },
  props: {
    route: {
      type: Object,
      default: () => ({ name: 'Catalog' })
    }
  }
}
</script>
