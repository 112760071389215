<template>
  <h2>{{ section.name }}</h2>
  <table class="table">
    <thead>
      <tr>
        <th class="w160p text-center">
          Gencode
        </th>
        <th>Désignation</th>
        <th class="w25">
          Commentaire étiquette
        </th>
        <th class="w100p">
          Quantité
        </th>
        <th class="w120p text-right">
          Prix Unitaire
        </th>
        <th class="w120p text-right">
          Montant
        </th>
      </tr>
    </thead>
    <tbody class="t-overable">
      <tr
        v-for="product in section.products"
        :key="product.id"
      >
        <td class="text-center">
          {{ product.gencode }}
        </td>
        <td>{{ product.desi }}</td>
        <td>{{ product.comment }}</td>
        <td class="text-right">
          {{ product.quantity }}
        </td>
        <td class="text-right">
          {{ preorder ? ' - ' : product.unitPrice }}
        </td>
        <td class="text-right">
          {{ preorder ? ' - ' : product.totalPrice }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="recapContainer w100">
    <table class="inline-block mt2 mb1">
      <tr>
        <td class="text-bold text-big pr3">
          Sous-total
        </td>
        <td class="text-bold text-big">
          {{ preorder ? 'Définis lors de la facturation' : section.total }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderTable',
  props: {
    section: {
      type: Object,
      default: () => ({
        name: '',
        products: [],
        total: 0
      })
    },
    preorder: {
      type: String,
      default: null
    }
  }
}
</script>
