
export const createAuthGuard = (store) => (to, from, next) => {
  const hasAuthCookie = document.cookie.indexOf('XSRF-TOKEN') !== -1
  const needsLogin = !hasAuthCookie || (to.meta?.requiresAuth && !store.state.auth.authenticated)
  if (to.name !== 'Login' && needsLogin) {
    next({ name: 'Login' })
  } else if (to.meta?.needDataFetch) {
    store.dispatch('catalog/initPolling')
    next()
  } else {
    next()
  }
}

export const createRestoreStoreGuard = (store) => async (to, from, next) => {
  await store.restored
  next()
}
