<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59 80"
    :class="cssClass"
  >
    <defs>
      <linearGradient
        id="logo-a"
        x1="52.958%"
        x2="44.017%"
        y1="59.25%"
        y2="31.043%"
      >
        <stop
          offset="0%"
          stop-color="#88AD40"
        />
        <stop
          offset="27.94%"
          stop-color="#9BBB47"
        />
        <stop
          offset="72.6%"
          stop-color="#B3CD51"
        />
        <stop
          offset="100%"
          stop-color="#BCD454"
        />
      </linearGradient>
      <linearGradient
        id="logo-b"
        x1="49.97%"
        x2="49.97%"
        y1="13.27%"
        y2="88.776%"
      >
        <stop
          offset="0%"
          stop-color="#445D2F"
        />
        <stop
          offset="1.903%"
          stop-color="#455F2F"
        />
        <stop
          offset="44.9%"
          stop-color="#648738"
        />
        <stop
          offset="79.08%"
          stop-color="#769F3E"
        />
        <stop
          offset="100%"
          stop-color="#7DA840"
        />
      </linearGradient>
      <linearGradient
        id="logo-c"
        x1="38.123%"
        x2="49.084%"
        y1="94.813%"
        y2="55.046%"
      >
        <stop
          offset="0%"
          stop-color="#88AD40"
        />
        <stop
          offset="27.94%"
          stop-color="#9BBB47"
        />
        <stop
          offset="72.6%"
          stop-color="#B3CD51"
        />
        <stop
          offset="100%"
          stop-color="#BCD454"
        />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="#623970"
        d="M6.047 76.762v.375h8.401v2.81H2.97C.83 80.052 0 78.795 0 76.708c0-.482.027-.937.08-1.365h2.328v.75c0 .642.268 1.016.83 1.07h.749v-1.82h.642c.749.027 1.418.67 1.418 1.418zm-4.575-7.277h12.976v2.81H.08v-1.472c0-.723.642-1.338 1.392-1.338zM8.91 56.134c3.853 0 5.779 1.712 5.779 5.083 0 3.372-1.953 5.084-5.78 5.084-3.879 0-5.805-1.686-5.805-5.084 0-3.371 1.926-5.083 5.806-5.083zm0 7.304c2.167-.027 3.264-.75 3.264-2.194 0-1.445-1.15-2.167-3.371-2.167-2.168 0-3.265.722-3.265 2.167.054 1.445 1.151 2.194 3.372 2.194zm-.214-10.355c-3.612-.08-5.405-2.006-5.405-5.752v-.401h2.916v.963c0 1.552.83 2.354 2.462 2.354h5.78v2.863H8.695v-.027zM.08 42.033c0-.936.455-1.364 1.365-1.364h.83v2.81H.08v-1.446zm3.184 0c0-.91.455-1.364 1.365-1.364h9.82v2.81H3.263v-1.446zm4.04-7.465h7.144v2.863H7.893c-3.184 0-4.79-1.605-4.79-4.789 0-1.445.322-2.542.964-3.344-.615-.803-.91-1.9-.91-3.345 0-3.184 1.579-4.79 4.79-4.762h6.581v1.471c-.053.937-.508 1.391-1.39 1.391h-5.78c-1.15.054-1.74.696-1.74 1.954 0 1.257.563 1.872 1.74 1.9h7.09v2.835H7.304c-1.15.054-1.739.696-1.739 1.954.027 1.204.589 1.846 1.74 1.872zM6.181 14.983v2.81C4.12 17.445 3.104 16 3.104 13.405c0-3.077 1.016-4.656 3.077-4.71H9.98c3.077 0 4.468 1.873 4.682 4.87.214 2.676-1.043 4.549-3.505 4.549-2.675-.054-3.398-2.007-3.665-4.843-.16-1.204-.509-1.82-1.124-1.82-.642.054-.963.643-.963 1.82 0 .99.267 1.552.776 1.712zm3.692-3.558H8.829c.268.642.509 1.418.67 2.247.213 1.124.748 1.686 1.551 1.686.883-.054 1.311-.535 1.311-1.418.027-1.579-.91-2.515-2.488-2.515zM5.27 2.114v.294h6.18c.455 0 .723-.268.723-.83V.722h2.14c.08.91.08 1.9-.026 2.97-.107 1.044-.75 1.552-2.007 1.552H.08V3.772c0-.749.668-1.364 1.417-1.364h1.766V.722h.616c.802 0 1.39.616 1.39 1.392z"
      />
      <path
        fill="#83C1FF"
        d="M35.479 47.867c.187 0 .401-.027.588-.027a4.745 4.745 0 0 1-.588.027c-.027 0-.027 0 0 0zm5.137-.91c5.565-1.766 10.435-6.02 11.157-12.388-.722 6.26-5.512 10.595-11.157 12.388z"
        opacity=".57"
      />
      <path
        fill="url(#logo-a)"
        d="M16.027 0h-.803C10.408 0 .241 5.351.241 15.25v10.436C3.425 16.91 15.33 14.93 16.268 14.983c.32-.026.561-.026.722-.026.187 0 .401-.027.589-.027h.026c.241-.027.455-.027.696-.054h.08c.214-.027.428-.053.616-.08.053 0 .107-.027.16-.027.187-.027.375-.053.562-.107.054 0 .134-.027.187-.027.188-.026.375-.08.535-.107.08-.026.134-.026.215-.053.187-.054.374-.08.561-.134.054-.027.134-.027.188-.053.214-.054.401-.107.615-.188.027 0 .08-.026.107-.026 5.619-1.793 10.435-6.128 11.157-12.388v-.027l.08-.803c.027-.294.027-.562.027-.856H16.027Z"
        transform="translate(18.462 32.91)"
      />
      <path
        fill="url(#logo-b)"
        d="M16.268 47.893C15.33 47.839 3.452 49.819.24 58.595V80c.267 0 .535 0 .802-.027 7.974-.455 15.251-7.705 15.251-14.956V47.893h-.026Z"
        transform="translate(18.462)"
      />
      <path
        fill="url(#logo-c)"
        d="M.24 34.247V23.545c0-6.956 8.295-13.913 14.984-13.913h10.702C34.488 9.632 39.84 0 39.84 0v11.773c0 10.167-10.434 13.913-14.983 13.913H14.69c-9.632 0-14.448 8.561-14.448 8.561Z"
        transform="translate(18.462)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
}
</script>
