<template>
  <button
    type="button"
  >
    <svg
      v-if="icon === 'trash'"
      height="20"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    ><path
      d="M6 0 5 1H0v2h16V1h-5l-1-1zM1 5v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5zm3 2h2v11H4zm6 0h2v11h-2z"
      fill="#bf2600"
    /></svg>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
