<template>
  <svg
    :class="cssClass"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
  ><path
    fill="#5A5555"
    d="M1.797 0a.617.617 0 0 0-.087.008H.608a.603.603 0 0 0-.526.289.569.569 0 0 0 0 .587c.109.182.31.292.526.29h.599v1.165c0 2.687 1.864 4.11 2.675 4.661-.81.552-2.675 1.974-2.675 4.661v1.166h-.6a.603.603 0 0 0-.525.289.569.569 0 0 0 0 .587c.109.182.31.292.526.29h1.097c.065.01.131.01.196 0h8.192c.065.01.131.01.196 0h1.103a.603.603 0 0 0 .526-.29.569.569 0 0 0 0-.587.603.603 0 0 0-.526-.29h-.599v-1.165c0-2.687-1.864-4.11-2.675-4.66.81-.552 2.675-1.975 2.675-4.662V1.173h.6a.603.603 0 0 0 .525-.289.569.569 0 0 0 0-.587.603.603 0 0 0-.526-.289h-1.106a.617.617 0 0 0-.188 0h-8.2A.617.617 0 0 0 1.797 0Zm.662 2.921h7.082c-.364 2.19-2.65 3.58-2.65 3.58A.58.58 0 0 0 6.6 7c0 .204.11.393.29.499 0 0 2.287 1.389 2.651 3.579h-1.23C8.044 10.075 7.115 9.33 6 9.33s-2.044.744-2.311 1.748h-1.23c.364-2.19 2.65-3.58 2.65-3.58A.58.58 0 0 0 5.4 7a.58.58 0 0 0-.29-.5S2.822 5.111 2.458 2.921Z"
  /></svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
}
</script>
