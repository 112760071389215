import axios from 'axios'
import { ValidationError } from '../errors'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    depot: null,
    lastFetch: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
    depot (state) {
      return state.depot
    },
    lastFetch (state) {
      return state.lastFetch
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
    SET_USER (state, value) {
      state.user = value
    },
    SET_DEPOT (state, value) {
      state.depot = value
    },
    SET_LAST_FETCH (state, value) {
      state.lastFetch = value
    }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
      try {
        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/api/login', credentials)
        await dispatch('me')
      } catch (error) {
        if (error?.response?.status === 422) {
          throw new ValidationError(error.response.data.message, error.response.data.errors)
        } else {
          throw new ValidationError('Une erreur inattendue est survenue')
        }
      }
    },

    signOut ({ dispatch }) {
      try {
        axios.post('/api/logout')
        dispatch('clear')
        dispatch('cart/clear', null, { root: true })
        dispatch('catalog/clear', null, { root: true })
        dispatch('order/clear', null, { root: true })
      } catch (error) {
        console.log(error)
      }
    },

    me ({ commit, dispatch }) {
      return axios.get('/api/me').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.user)
        commit('SET_DEPOT', response.data.depot)
      }).catch(() => {
        dispatch('clear')
      })
    },

    clear ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_DEPOT', null)
    }
  }
}
