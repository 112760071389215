<template>
  <back class="mb2" />
  <h1 class="title-1">
    <icon-error /> Erreur commande
  </h1>
  <div>
    <p>Votre commande n'a pas été validée. Merci de nous contacter directement pour plus d'informations</p>
  </div>
</template>

<script>
import IconError from '@/components/svg/Error.vue'
import Back from '@/components/misc/Back.vue'

export default {
  name: 'OrderSuccess',
  components: {
    Back,
    IconError
  }
}
</script>
