<template>
  <div class="main">
    <router-view />
  </div>
</template>
<script>
export default {
}
</script>

<style lang="scss">
@import "./assets/scss/style.scss";
</style>
