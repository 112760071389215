import axios from 'axios'
import { ValidationError } from '../errors'

export default {
  namespaced: true,
  state: {
    products: {},
    orderCategories: [],
    preOrderCategories: [],
    comments: {},
    quantity: 0,
    orderQuantity: 0,
    preOrderQuantity: 0,
    total: 0,
    orderId: null
  },
  getters: {
    productQuantities (state) {
      const result = {}
      Object.keys(state.products).forEach(key => {
        result[key] = state.products[key].quantity
      })
      return result
    },
    comments (state) {
      return state.comments
    }
  },
  mutations: {
    UPDATE_CART (state, cart) {
      state.products = cart.products
      state.orderCategories = cart.order_categories
      state.preOrderCategories = cart.pre_order_categories
      state.quantity = cart.total_quantity
      state.orderQuantity = cart.order_quantity
      state.preOrderQuantity = cart.pre_order_quantity
      state.total = cart.total
    },
    SET_COMMENT (state, { productId, comment }) {
      state.comments[productId] = comment
    },
    SET_ORDER_ID (state, orderId) {
      state.orderId = orderId
    },
    RESET_CART (state) {
      state.products = {}
      state.orderCategories = []
      state.preOrderCategories = []
      state.comments = {}
      state.quantity = 0
      state.orderQuantity = 0
      state.preOrderQuantity = 0
      state.total = 0
      state.orderId = null
    },
    SET_PRODUCT_QUANTITY (state, { productId, quantity }) {
      if (productId in state.products) {
        state.products[productId].quantity = quantity
      }
    }
  },
  actions: {
    async fetchCart ({ commit }) {
      return axios.get('/api/cart').then((response) => {
        commit('UPDATE_CART', response.data.data)
      }).catch((e) => {
        console.error('Unable to get cart')
      })
    },
    async addProduct ({ commit }, [id, quantity, categoryId]) {
      return axios.post('/api/cart/add', {
        product_id: id,
        quantity: quantity,
        category_id: categoryId
      }).then((response) => {
        commit('UPDATE_CART', response.data.data)
      }).catch((error) => {
        if (error?.response?.status === 422) {
          throw new ValidationError(error.response.data.message, error.response.data.errors ?? [])
        }
        throw new Error('Une erreur inattendue est survenue')
      })
    },
    async removeProduct ({ commit }, [id, categoryId]) {
      return axios.post('/api/cart/remove', {
        product_id: id,
        category_id: categoryId
      }).then((response) => {
        commit('UPDATE_CART', response.data.data)
      }).catch((e) => {
        console.error('Unable to remove product "' + id + '"')
      })
    },
    editComment ({ commit }, [productId, comment]) {
      commit('SET_COMMENT', { productId, comment })
    },
    async orderCart ({ commit, state }, formData) {
      const commentData = {}
      for (const key in state.comments) {
        const comment = state.comments[key]
        commentData[key] = comment
      }

      const data = {
        comments: commentData,
        comment: formData.comment
      }

      if (formData.pickup_date) {
        data.pickup_date = formData.pickup_date.getFullYear() + '-' + (formData.pickup_date.getMonth() + 1) + '-' + formData.pickup_date.getDate()
      }

      if (formData.delivery_date) {
        data.delivery_date = formData.delivery_date.getFullYear() + '-' + (formData.delivery_date.getMonth() + 1) + '-' + formData.delivery_date.getDate()
      }

      try {
        const response = await axios.post('/api/order', data)
        commit('RESET_CART')
        commit('SET_ORDER_ID', response.data.orderId)
      } catch (error) {
        if (error?.response?.status === 422) {
          throw new ValidationError(error.response.data.message, error.response.data.errors)
        } else {
          throw new ValidationError('Une erreur inattendue est survenue')
        }
      }
    },
    async clearCart ({ commit }) {
      try {
        await axios.post('/api/cart/clear')
        commit('RESET_CART')
      } catch (error) {
        console.error(error)
        throw new ValidationError('Une erreur inattendue est survenue')
      }
    },
    clear ({ commit }) {
      commit('RESET_CART')
    },
    setProductQuantity ({ commit }, payload) {
      commit('SET_PRODUCT_QUANTITY', payload)
    }
  }
}
