<template>
  <div class="searchbox searchbox--stickHeader mb3 full-width">
    <div class="container">
      <input
        v-model="query"
        class="flex-shrink w100 mw0"
        type="search"
        placeholder="Rechercher un produit"
        @input="queryChanged"
      >
    </div>
  </div>
  <div class="flex gap catalog">
    <aside class="catalog-sidebar">
      <select
        v-model="topcategoryId"
        class="mb3"
        @change="onCategoryChanged"
      >
        <option
          v-for="cat in categories"
          :key="cat.id"
          :value="cat.id"
        >
          {{ cat.name }}
        </option>
      </select>
      <div class="categories md:flex-grow">
        <div
          v-for="subcategory in topcategory?.children"
          :key="subcategory.id"
          class="categories__item"
          :class="{selected: subcategory.selected}"
        >
          <a
            :class="{
              nochild: !subcategory?.children?.length,
              expanded: subcategory.expanded,
            }"
            @click="toggle(subcategory, true)"
          >{{ subcategory?.name }}</a>
          <div
            v-for="subsubcategory in subcategory?.children"
            :key="subsubcategory.id"
            class="categories__sub"
            :class="{selected: subsubcategory.selected}"
          >
            <a
              :class="{
                expanded: subsubcategory.expanded,
                nochild: !subsubcategory?.children?.length,
              }"
              @click="toggle(subsubcategory)"
            >
              {{ subsubcategory?.name }}
            </a>
            <div
              v-for="ssscategory in subsubcategory?.children"
              :key="ssscategory.id"
              class="categories__sub categories__sub--last"
              :class="{selected: ssscategory.selected}"
            >
              <a
                :class="{ expanded: ssscategory.expanded }"
                @click="toggle(ssscategory)"
              >
                {{ ssscategory?.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <section>
      <div class="flex justify-end">
        <select
          v-model="display"
          class="mb3"
        >
          <option value="table">
            Vue en liste
          </option>
          <option value="deck">
            Vue en grille
          </option>
        </select>
      </div>
      <div
        v-if="selectedcategory "
        class="flex-grow mw0"
      >
        <Table
          v-if="display === 'table'"
          :products="paginatedProducts"
          :top-category="topcategory"
        />
        <Deck
          v-if="display === 'deck'"
          :products="paginatedProducts"
          :top-category="topcategory"
        />
        <div />

        <Pagination
          :length="filteredProducts.length"
          :current="page"
          :item-per-page="itemPerPage"
          :route="{name: 'Catalog', params: { category: categorySlug }}"
        />

        <div class="flex justify-end">
          <go-top />
        </div>
      </div>

      <div
        v-else
        class="flex-grow mw0"
      >
        <p>
          Veuillez sélectionner une catégorie
        </p>
      </div>
    </section>
    <section class="catalog-recap">
      <table class="text-big">
        <tbody>
          <tr>
            <td class="text-right">
              Articles
            </td>
            <td class="text-right text-bold pl3">
              {{ cart_quantity }}
            </td>
          </tr>
          <tr>
            <td class="text-right">
              Total HT
            </td>
            <td class="text-right text-bold pl3">
              {{ cart_total }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt1">
        <router-link to="/cart">
          <button
            class="btn btn--secondary btn--ico btn--icoLeft btn--big text-uppercase"
          >
            <icon-devis /> Finaliser ma commande
          </button>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>

import IconDevis from '@/components/svg/Devis.vue'

import Deck from '@/components/catalog/Deck.vue'
import Table from '@/components/catalog/Table.vue'
import { mapState } from 'vuex'
import { dfs } from '@/helpers'
import slugify from 'slugify'
import Pagination from '@/components/misc/Pagination.vue'
import GoTop from '@/components/ui/GoTop.vue'

export default {
  name: 'Catalog',
  components: {
    IconDevis,
    Deck,
    Table,
    Pagination,
    GoTop
  },
  props: {
    page: {
      type: Number,
      default: () => (1)
    }
  },
  data () {
    return {
      itemPerPage: 50,
      topcategoryId: null,
      selectedcategory: null,
      display: 'deck',
      query: ''
    }
  },
  computed: {
    ...mapState({
      categories: state => state.catalog.categories,
      products: state => state.catalog.products,
      cart_total: state => state.cart.total,
      cart_quantity: state => state.cart.quantity
    }),
    categorySlug () {
      if (!this.selectedcategory) {
        return undefined
      }
      return slugify(this.selectedcategory.id + '-' + this.selectedcategory.name)
    },
    topcategory () {
      return this.getCategoryById(this.topcategoryId)
    },
    filteredProducts () {
      let selectedCategoryIds = []
      if (this.selectedcategory) {
        selectedCategoryIds = this.getCategoryIds(this.selectedcategory)
      }
      return this.products.filter((product) => {
        let ok = true
        // on ne filtre pas les catégories s'il y a une recherche textuelle
        if (!this.query && selectedCategoryIds.length) {
          const list = product.categories.filter(value => selectedCategoryIds.includes(value))
          ok = ok && list.length
        }

        if (this.query) {
          const search = this.query.toLowerCase()
          ok = ok && product.is_searchable && (product.name.toLowerCase().includes(search) || product.gencode.toLowerCase().includes(search))
        }
        return ok
      }).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })
    },
    paginatedProducts () {
      return this.filteredProducts.slice((this.page - 1) * this.itemPerPage, (this.page - 1) * this.itemPerPage + this.itemPerPage)
    }
  },
  watch: {
    categories (value, old) {
      this.initSelectedcategory()
    }
  },
  created () {
    if (this.categories) {
      this.initSelectedcategory()
    }
  },
  methods: {
    initSelectedcategory () {
      if (this.$route.params.category) {
        const categoryId = this.$route.params.category.substr(0, this.$route.params.category.indexOf('-'))
        if (categoryId) {
          dfs(this.categories, (cat, path) => {
            if (cat.id.toString() === categoryId) {
              this.topcategoryId = path[0].id

              for (let index = 1; index < path.length; index++) {
                const element = path[index]
                element.expanded = true
              }

              this.selectedcategory = cat
              this.selectedcategory.selected = true
            }
          })
        }
      } else {
        this.selectFirstCategoryWithProducts()
      }
    },
    selectFirstCategoryWithProducts () {
      let categoryProductCount = this.countProductsInCategory()
      categoryProductCount = this.countProductsInRootCategories(categoryProductCount)

      let selectedcategory = null
      for (const category of this.categories) {
        if (category.id in categoryProductCount && categoryProductCount[category.id]) {
          selectedcategory = category
          break
        }
      }

      if (selectedcategory) {
        this.topcategoryId = selectedcategory.id
      } else {
        this.topcategoryId = this.categories[0].id
      }

      this.selectedcategory = this.topcategory
      this.selectedcategory.selected = true
    },
    countProductsInCategory () {
      const categoryProductCount = {}
      for (const product of this.products) {
        for (const categoryId of product.categories) {
          if (!(categoryId in categoryProductCount)) {
            categoryProductCount[categoryId] = 0
          }
          categoryProductCount[categoryId]++
        }
      }

      return categoryProductCount
    },
    countProductsInRootCategories (categoryProductCount) {
      dfs(this.categories, (category, path) => {
        if (path.length > 1 && category.id in categoryProductCount) {
          const mainCategory = path[0]
          if (!(mainCategory.id in categoryProductCount)) {
            categoryProductCount[mainCategory.id] = 0
          }
          categoryProductCount[mainCategory.id] += categoryProductCount[category.id]
        }
      })

      return categoryProductCount
    },
    onCategoryChanged () {
      let categorySlug = null
      if (this.topcategory) {
        this.selectedcategory = this.topcategory
        categorySlug = slugify(this.topcategory.id + '-' + this.topcategory.name)
      }
      this.$router.push({ name: 'Catalog', params: { category: categorySlug } })
    },
    toggle (category, isTop) {
      const unselect = this.selectedcategory && this.selectedcategory.id === category.id
      this.selectedcategory = category

      if (category.children.length) {
        category.expanded = !unselect
      }

      dfs([this.topcategory], (cat, path) => {
        cat.expanded = false
        if (unselect) {
          cat.selected = false
        } else {
          if (cat.id === category.id) {
            for (const c of path) {
              c.expanded = true
            }
            cat.selected = true
          } else {
            cat.selected = false
          }
        }
      })

      if (unselect) {
        this.selectedcategory = this.topcategory
      }

      const categorySlug = slugify(this.selectedcategory.id + '-' + this.selectedcategory.name)
      this.$router.push({ name: 'Catalog', params: { category: categorySlug }, hash: isTop ? '#no-scroll' : '' })
    },
    getCategoryIds (category) {
      const categoryIds = []
      if (category) {
        dfs([category], (category) => {
          categoryIds.push(category.id)
        })
      }

      return categoryIds
    },
    getCategoryById (categoryId) {
      for (const category of this.categories) {
        if (category.id === categoryId) {
          return category
        }
      }

      return null
    },
    queryChanged () {
      if (this.query && this.page !== 1) {
        this.$router.push({ name: 'Catalog', query: { page: 1 } })
      }
    }
  }
}
</script>
