<template>
  <button
    :class="{
      'loading': loading,
    }"
    :disabled="loading"
    type="button"
    class="btn btn--goTop"
    @click="scrollToTop"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
    ><path
      fill="#60446A"
      d="M7.5 0 0 7.69 2.253 10 7.5 4.62 12.747 10 15 7.69z"
    /></svg>
    Remonter
  </button>
</template>

<script>
export default {
  name: 'GoTop',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
