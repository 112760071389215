<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="19"
    height="25"
  ><defs><path
    id="devis-icon-a"
    d="M.777.25h4.75V5H.777z"
  /><path
    id="devis-icon-c"
    d="M0 24.25h18.527V0H0z"
  /></defs><g
    fill="none"
    fill-rule="evenodd"
  ><path
    fill="#00884B"
    d="M11.777 23.25v-5.224a.5.5 0 0 1 .5-.5H17.5a.5.5 0 1 1 0 1h-4.723v4.724a.5.5 0 0 1-1 0Z"
  /><path
    fill="#00884B"
    d="M11.57 23.957a.999.999 0 0 1 0-1.414l5.223-5.224a1 1 0 0 1 1.414 1.414l-5.223 5.224a.997.997 0 0 1-1.414 0Z"
  /><g transform="translate(13 19.25)"><mask
    id="devis-icon-b"
    fill="#fff"
  ><use xlink:href="#devis-icon-a" /></mask><path
    fill="#5A5555"
    d="M1.07 4.707a.999.999 0 0 1 0-1.414L3.82.543a.999.999 0 1 1 1.414 1.414l-2.75 2.75a.997.997 0 0 1-1.414 0Z"
    mask="url(#devis-icon-b)"
  /></g><path
    fill="#00884B"
    d="M9.863 9.18H4.75a.5.5 0 0 1 0-1h5.113a.5.5 0 1 1 0 1m3.375 2.633H4.75a.5.5 0 0 1 0-1h8.488a.5.5 0 0 1 0 1m0 2.632H4.75a.5.5 0 0 1 0-1h8.488a.5.5 0 0 1 0 1m-4.933 2.631H4.75a.5.5 0 0 1 0-1h3.555a.5.5 0 0 1 0 1"
  /><mask
    id="devis-icon-d"
    fill="#fff"
  ><use xlink:href="#devis-icon-c" /></mask><path
    fill="#00884B"
    d="M2 22.25h14.5V3H2v19.25Zm15.5 2H1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h16.5a1 1 0 0 1 1 1v21.25a1 1 0 0 1-1 1Z"
    mask="url(#devis-icon-d)"
  /><path
    fill="#00884B"
    d="M6.125 2.375A.126.126 0 0 0 6 2.5c0 .069.057.125.125.125h6.75c.07 0 .125-.056.125-.125a.124.124 0 0 0-.125-.125h-6.75ZM12.875 4h-6.75C4.953 4 4 3.103 4 2s.953-2 2.125-2h6.75C14.047 0 15 .897 15 2s-.953 2-2.125 2Z"
    mask="url(#devis-icon-d)"
  /></g></svg>
</template>
