<template>
  <svg
    :class="cssClass"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
  ><path
    fill="currentColor"
    d="M3 0C1.355 0 0 1.355 0 3v12c0 1.645 1.355 3 3 3h8.5c1.388 0 2.624-.91 2.969-2.251a1 1 0 1 0-1.938-.498c-.098.384-.555.749-1.03.749H3c-.564 0-1-.436-1-1V3c0-.564.436-1 1-1h8.5c.476 0 .933.365 1.031.749a1 1 0 0 0 1.938-.498C14.124.91 12.889 0 11.5 0H3Zm10.99 4.99a1 1 0 0 0-.697 1.717L14.586 8H5.5a1 1 0 1 0 0 2h9.086l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-.718-.303Z"
  /></svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
}
</script>
