<template>
  <button
    :class="{
      'loading': loading,
    }"
    :disabled="loading"
    type="button"
  >
    <svg
      v-if="loading"
      class="spinner"
      viewBox="0 0 50 50"
    >
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      />
    </svg>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

button.loading {
  cursor: not-allowed;
}

button svg {
  margin-right: 1rem;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 2.2rem;
  height: 2.2rem;
}

.spinner .path {
    stroke: rgb(255, 255, 255);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>
