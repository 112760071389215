import { createStore } from 'vuex'
import auth from './auth'
import cart from './cart'
import catalog from './catalog'
import order from './order'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'
import { clone } from 'pouchdb-utils'

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  // storage: window.localStorage,
  reducer: (state) => clone(state)
  // reducer: (state) => ({
  //   auth: state.auth,
  //   cart: state.cart,
  //   catalog: {
  //     // on ne sauvegarde pas pollingInterval
  //     categories: state.catalog.categories,
  //     products: state.catalog.products
  //   },
  //   order: state.order
  // })
})

export default createStore({
  modules: {
    auth,
    cart,
    catalog,
    order
  },
  plugins: [vuexLocal.plugin]
})
