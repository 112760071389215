import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import createRouter from './router'
import store from './store'
import axios from 'axios'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)
const router = createRouter(store)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('auth/signOut')
      router.replace({ name: 'Login' })
    }
    return Promise.reject(error)
  }
)

app.use(store).use(router).use(VueLazyLoad).mount('#app')

app.config.globalProperties.$notyf = new Notyf({
  duration: 6000,
  position: {
    x: 'right',
    y: 'top'
  },
  dismissible: true,
  types: [
    {
      type: 'warning',
      background: 'orange',
      icon: {
        text: false
      }
    }
  ]
})
