<template>
  <td class="color-3">
    <div v-if="hasSpecialRate">
      <icon-question />
      <div class="tooltip tooltip--table">
        <div class="tooltip__title">
          Tarifs par quantité
        </div>
        <div>
          <div
            v-for="price in product.prices"
            :key="price.label"
            class="tooltip__line"
          >
            <div>{{ price.label }}</div>
            <div class="text-semibold">
              {{ price.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </td>
  <td>
    <img
      v-lazy="product?.images?.thumbnail?.url || ''"
      class="is-clickable"
      :alt="product?.images?.thumbnail?.alt || ''"
      @click="openModal(product?.images?.zoom?.url || '')"
    >
  </td>
  <td>{{ product.gencode }}</td>
  <td>
    {{ product.name }}
    <div
      v-show="product.discount"
      class="tag tag--red ml1"
    >
      {{ product.discount }}
    </div>
  </td>
  <td
    class="text-right"
    :class="{
    }"
  >
    {{ unitPrice }}
  </td>
  <td class="text-right">
    {{ product.packaging }}&nbsp;/&nbsp;EMB
  </td>
  <td class="text-right">
    <span v-if="topCategory.is_pre_order">
      <icon-hourglass />
    </span>
    <span v-else>
      {{ product.stock }}
    </span>
  </td>
  <td class="no-tooltip">
    <input
      v-model="quantity"
      class="f-small w100"
      type="number"
      :step="product.packaging"
      min="0"
      @change="updateQuantity"
    >
  </td>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconQuestion from '@/components/svg/Question.vue'
import IconHourglass from '@/components/svg/HourGlass.vue'

import debounce from 'debounce'
import tingle from 'tingle.js'

// eslint-disable-next-line
const modal = new tingle.modal({
  cssClass: ['modal-preview']
})

export default {
  name: 'Table',
  components: {
    IconQuestion,
    IconHourglass
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    topCategory: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      quantity: null
    }
  },
  computed: {
    ...mapGetters('cart', [
      'productQuantities'
    ]),
    cartQuantity () {
      return this.productQuantities[this.product.id + '-' + this.topCategory.id] || null
    },
    unitPrice () {
      if (this.topCategory.is_pre_order) {
        return this.product.pre_order_price === null ? 'Définis lors de la facturation' : this.product.pre_order_price
      }

      return this.product.prices[0].price || ''
    },
    hasSpecialRate () {
      return this.product.prices.length > 1 && !(this.topCategory.is_pre_order && this.product.pre_order_price === null)
    }
  },
  created () {
    this.quantity = this.cartQuantity
  },
  methods: {
    ...mapActions({ addProduct: 'cart/addProduct' }),
    updateQuantity: debounce(function () {
      this.quantity = Math.ceil(this.quantity / this.product.packaging) * this.product.packaging
      this.addProduct([this.product.id, this.quantity, this.topCategory.id])
        .then(() => {
          this.quantity = this.cartQuantity
        })
        .catch((error) => {
          this.$notyf.open({
            type: error.name === 'ValidationError' ? 'warning' : 'error',
            message: error.message
          })

          this.quantity = this.cartQuantity
        })
    }, 500),
    openModal (pictureURL) {
      modal.setContent('<img src="' + pictureURL + '">')
      modal.open()
    }
  }

}
</script>
