export class ValidationError extends Error {
  constructor (message, errors, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError)
    }
    this.name = 'ValidationError'
    this.message = message
    this.errors = errors
  }
}
