<template>
  <back class="mb2" />
  <h1 class="title-1">
    Commande n°{{ order.id }}
  </h1>

  <div
    v-for="section in order.order_categories"
    :key="section.id"
  >
    <OrderTable :section="section" />
  </div>
  <div v-if="order.pre_order_categories.length">
    <h2>Articles en précommande</h2>
    <div
      v-for="section in order.pre_order_categories"
      :key="section.id"
    >
      <OrderTable
        :section="section"
        preorder="true"
      />
    </div>
  </div>
  <div
    class="recapContainer w100"
  >
    <table class="inline-block mt2 mb1">
      <tr>
        <td class="text-bold text-big pr3">
          Total
        </td>
        <td class="text-bold text-big">
          {{ order.total }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Back from '@/components/misc/Back.vue'
import OrderTable from '@/components/order/OrderTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderDetail',
  components: {
    Back,
    OrderTable
  },
  computed: {
    ...mapGetters({
      getOrderById: 'order/getOrderById'
    }),
    order () {
      return this.getOrderById(this.$route.params.orderId)
    }
  }
}
</script>
