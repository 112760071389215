<template>
  <table class="table">
    <thead>
      <tr>
        <th class="w50p" />
        <th class="w100p">
          Image
        </th>
        <th class="w160p">
          Gencod
        </th>
        <th class="w75">
          Désignation
        </th>
        <th class="w25 text-right">
          Prix
        </th>
        <th class="w120p text-right">
          Nb. art/emb
        </th>
        <th class="w100p text-right">
          Stock
        </th>
        <th class="w100p">
          Qté
        </th>
      </tr>
    </thead>
    <tbody class="t-overable">
      <template v-if="products.length">
        <tr
          v-for="product in products"
          :key="product.id"
        >
          <Row
            :product="product"
            :top-category="topCategory"
          />
        </tr>
      </template>
      <tr v-else>
        <td
          colspan="8"
          class="text-center"
        >
          En cours de réapprovisionnement
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Row from '@/components/catalog/Row.vue'
export default {
  name: 'Table',
  components: {
    Row
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    topCategory: {
      type: Object,
      default: () => {
        return {
          id: 1
        }
      }
    }
  }
}
</script>
