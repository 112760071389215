<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  ><path
    fill="#40C057"
    d="M21.333 0H2.667A2.666 2.666 0 0 0 0 2.667v18.666A2.666 2.666 0 0 0 2.667 24h18.666A2.666 2.666 0 0 0 24 21.333V2.667A2.666 2.666 0 0 0 21.333 0Zm-12 19.219-6.276-6.276 1.886-1.886 4.39 4.391 9.724-9.724 1.886 1.885-11.61 11.61Z"
  /></svg>
</template>
