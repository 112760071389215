<template>
  <div class="pagination">
    <router-link
      v-for="page in pages"
      :key="page"
      :to="{ ...route, query: { page: page } }"
      class="pagination-button"
      :class="page === current ? 'is-current' : ''"
    >
      {{ page }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    length: {
      type: Number,
      default: () => (0)
    },
    itemPerPage: {
      type: Number,
      default: () => (5)
    },
    current: {
      type: Number,
      default: () => (0)
    },
    route: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    size () {
      return Math.ceil(this.length / this.itemPerPage)
    },
    pages () {
      const pages = []
      for (var i = 1; i <= this.size; i++) {
        pages.push(i)
      }
      return pages
    }
  },
  methods: {

  }
}
</script>
