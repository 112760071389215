<template>
  <svg
    :class="cssClass"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
  ><g fill="#5A5555"><path d="M12.456 4.824a.912.912 0 0 0-1.296 0l-2.52 2.52-2.52-2.52a.912.912 0 0 0-1.296 0 .912.912 0 0 0 0 1.296l2.52 2.52-2.52 2.52a.912.912 0 0 0 0 1.296.914.914 0 0 0 1.297 0l2.52-2.52 2.52 2.52a.914.914 0 0 0 1.296 0 .912.912 0 0 0 0-1.296l-2.52-2.52 2.52-2.52a.914.914 0 0 0-.001-1.296Z" /><path d="M12.132.09H5.148A5.063 5.063 0 0 0 .09 5.148v6.985a5.063 5.063 0 0 0 5.058 5.058h6.984a5.063 5.063 0 0 0 5.059-5.058l-.001-6.985A5.063 5.063 0 0 0 12.132.09Zm3.24 12.042c0 1.781-1.44 3.24-3.24 3.24H5.148a3.238 3.238 0 0 1-3.24-3.24V5.148c0-1.782 1.44-3.24 3.24-3.24h6.984c1.782 0 3.24 1.44 3.24 3.24v6.984Z" /></g></svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
}
</script>
