import axios from 'axios'

export default {
  namespaced: true,
  state: {
    orders: []
  },
  mutations: {
    UPDATE_ORDERS (state, orders) {
      state.orders = orders
    }
  },
  getters: {
    getOrderById: (state) => (id) => {
      // eslint-disable-next-line eqeqeq
      return state.orders.find(order => order.id == id)
    }
  },
  actions: {
    async fetchOrders ({ commit }) {
      return axios.get('/api/orders').then((response) => {
        commit('UPDATE_ORDERS', response.data.data)
      }).catch((e) => {
        console.error('Unable to get orders')
      })
    },
    clear ({ commit }) {
      commit('UPDATE_ORDERS', [])
    }
  }
}
