<template>
  <h2>{{ section.name }}</h2>
  <table class="table">
    <thead>
      <tr>
        <th class="w160p text-center">
          Gencode
        </th>
        <th>Designation</th>
        <th class="w25">
          Commentaire étiquette
        </th>
        <th class="w120p text-center">
          Qté E1
        </th>
        <th class="w100p">
          Quantité
        </th>
        <th class="w120p text-right">
          Prix unitaire
        </th>
        <th class="w120p text-right">
          Montant
        </th>
        <th class="w50p" />
      </tr>
    </thead>
    <tbody class="t-overable">
      <template v-if="Object.keys(section.products).length">
        <tr
          v-for="cartProduct in section.products"
          :key="cartProduct.id"
        >
          <ProductRow :cart-product="cartProduct" />
        </tr>
      </template>
      <tr v-else>
        <td
          colspan="8"
          class="text-center"
        >
          Aucun produit dans le panier
        </td>
      </tr>
    </tbody>
  </table>
  <div class="recapContainer w100">
    <table class="inline-block mt2 mb1">
      <tr>
        <td class="text-bold text-big pr3">
          Sous-total
        </td>
        <td class="text-bold text-big">
          {{ section.total }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import ProductRow from '@/components/cart/ProductRow.vue'

export default {
  name: 'CartSection',
  components: {
    ProductRow
  },
  props: {
    section: {
      type: Object,
      default: () => ({
        name: '',
        products: [],
        total: 0
      })
    }
  },
  methods: {
  }
}
</script>
