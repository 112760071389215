<template>
  <back class="mb2" />
  <h1 class="title-1">
    <icon-success /> Commande confirmée
  </h1>
  <div>
    <p>Votre commande a bien été enregistrée sous le n°{{ orderId }}.</p>
    <!-- <p>Un e-mail de confirmation va vous être envoyé à l’adresse <span class="color-1 text-bold">{{ email }}</span>.</p>
    <p>Si vous ne recevez rien d’ici quelques minutes, nous vous invitons à consulter votre dossier « courriers indésirables ».</p> -->
  </div>
</template>

<script>
import IconSuccess from '@/components/svg/Success.vue'
import Back from '@/components/misc/Back.vue'
import { mapState } from 'vuex'

export default {
  name: 'OrderSuccess',
  components: {
    Back,
    IconSuccess
  },
  computed: {
    ...mapState({
      orderId: state => state.cart.orderId,
      email: state => state.auth.user.email
    })
  }
}
</script>
