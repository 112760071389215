<template>
  <header
    class="header"
  >
    <div class="container">
      <router-link
        :to="{name: 'Catalog'}"
        class="header-title"
      >
        <icon-logo />
        <h1>
          <div class="header-title__primary">
            Florimat {{ depot?.name }}
          </div>
          <div class="header-title__secondary">
            {{ user?.name }} • {{ user?.invoice_city }}
          </div>
        </h1>
      </router-link>
      <div
        class="header-actions"
      >
        <router-link :to="{name: 'Catalog'}">
          Catalogue
        </router-link>
        <router-link :to="{name: 'OrderHistory'}">
          Mes commandes
        </router-link>
        <a
          href="#"
          class="header-actionLogout"
          @click="signOut"
        >Déconnexion</a>
        <icon-logout css-class="header-icon ml1" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IconLogo from '../svg/Logo.vue'
import IconLogout from '../svg/Logout.vue'

export default {
  name: 'Header',
  components: {
    IconLogo,
    IconLogout
  },
  computed: {
    ...mapGetters('auth', [
      'authenticated',
      'user',
      'depot'
    ])
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
    }),
    async signOut () {
      this.signOutAction()
      this.$router.replace({ name: 'Login' })
    }
  }
}
</script>
