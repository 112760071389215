<template>
  <div class="about">
    <h1>Ma commande</h1>
  </div>

  <div
    v-for="section in sections"
    :key="section.id"
  >
    <CartSection :section="section" />
  </div>
  <div v-if="preOrderSections.length">
    <h2>Articles en précommande</h2>
    <div
      v-for="section in preOrderSections"
      :key="section.id"
    >
      <CartSection :section="section" />
    </div>
  </div>
  <div
    v-if="errors && errors.stocks"
    class="f-error"
  >
    <ul class="f-error-message">
      <li
        v-for="message in errors.stocks"
        :key="message"
      >
        {{ message }}
      </li>
    </ul>
  </div>

  <div
    class="recapContainer w100"
  >
    <table class="inline-block mt2 mb1">
      <tr>
        <td class="text-bold text-big pr3">
          Total
        </td>
        <td class="text-bold text-big">
          {{ cart.total }}
        </td>
      </tr>
    </table>

    <div>
      <label
        for="deliveryMode"
        class="text-bold"
      >Choisissez une méthode de livraison</label>
      <select
        id="deliveryMode"
        v-model="deliveryMode"
        class="w300p"
        name=""
      >
        <option value="depot">
          Récupération au dépôt
        </option>
        <option value="home">
          Livraison à mon adresse
        </option>
      </select>
    </div>

    <div>
      <label
        class="text-bold"
        for="deliveryDate"
      >{{ deliveryDateLabel }}</label>
      <Datepicker
        :id="deliveryDate"
        v-model="deliveryDate"
        class="w300p"
        auto-apply
        :allowed-dates="allowedDates"
        :format="formatDate"
        locale="fr"
        :enable-time-picker="false"
        select-text="Sélectionner"
        cancel-text="Annuler"
        now-button-label="Maintenant"
      />

      <div
        v-if="errors && (errors.delivery_date || errors.pickup_date)"
        class="f-error"
      >
        <ul
          v-if="deliveryMode==='depot'"
          class="f-error-message"
        >
          <li
            v-for="message in errors.pickup_date"
            :key="message"
          >
            {{ message }}
          </li>
        </ul>
        <ul
          v-if="deliveryMode==='home'"
          class="f-error-message"
        >
          <li
            v-for="message in errors.delivery_date"
            :key="message"
          >
            {{ message }}
          </li>
        </ul>
      </div>
    </div>

    <div>
      <label
        for="comment"
        class="text-bold"
      >Commentaire (facultatif)</label>

      <textarea
        id="comment"
        v-model="comment"
        placeholder="Saisissez ici votre commentaire"
        class="w400p"
      />
    </div>
    <hr class="separator mb2">
    <div>
      <span
        class="text-bold text-right mb1 inline-block"
      >Informations de facturation</span>
      <div class="text-right">
        <div>{{ user.name }}</div>
        <div>{{ user.invoice_address }} </div>
        <div v-if="user.invoice_address2">
          {{ user.invoice_address2 }}
        </div>
        {{ user.invoice_zipcode }} {{ user.invoice_city }}
      </div>
    </div>
  </div>
  <hr class="mb3 mt3 separator">
  <div class="flex justify-between align-center pb5">
    <div>
      <icon-button
        icon="trash"
        class="btn btn--danger"
        @click="clear"
      >
        Vider mon panier
      </icon-button>
    </div>
    <div>
      <button
        class="btn btn--clear"
        @click="cancel"
      >
        Poursuivre mes achats
      </button>
      <loading-button
        class="btn"
        :class="{
          'is-disabled': Object.keys(cart.products).length == 0,
          'btn--shadow': Object.keys(cart.products).length == 0,
          'btn--primary': Object.keys(cart.products).length > 0
        }"
        :loading="loading"
        :disabled="Object.keys(cart.products).length == 0"
        @click="order"
      >
        Valider ma commande
      </loading-button>
    </div>
  </div>
  <!-- <div>
    <go-top />
  </div> -->
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CartSection from '@/components/cart/CartSection.vue'
import LoadingButton from '@/components/ui/LoadingButton.vue'
import IconButton from '@/components/ui/IconButton.vue'
import Datepicker from 'vue3-date-time-picker'

export default {
  name: 'Cart',
  components: {
    CartSection,
    LoadingButton,
    IconButton,
    Datepicker
  },
  data () {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return {
      loading: false,
      errors: null,
      deliveryMode: 'depot',
      deliveryDateLabel: 'Choisissez une date de retrait',
      deliveryDate: date,
      comment: null

    }
  },
  computed: {
    ...mapGetters('auth', [
      'depot'
    ]),
    ...mapState({
      cart: state => state.cart,
      user: state => state.auth.user
    }),
    allowedDates () {
      if (this.depot && this.depot.schedules) {
        return Object.values(this.depot.schedules).map((schedule) => {
          return new Date(schedule * 1000)
        })
      }

      return []
    },
    sections () {
      return this.makeSections(this.cart?.orderCategories || [])
    },
    preOrderSections () {
      return this.makeSections(this.cart?.preOrderCategories || [])
    }

  },
  watch: {
    deliveryMode (value) {
      if (value === 'depot') {
        this.deliveryDateLabel = 'Choisissez une date de retrait'
      } else {
        this.deliveryDateLabel = 'Choisissez une date de livraison'
      }
    }
  },
  methods: {
    ...mapActions({
      orderCart: 'cart/orderCart',
      clearCart: 'cart/clearCart'
    }),
    async order () {
      if (this.loading) return
      try {
        this.loading = true
        const data = {
          comment: this.comment
        }
        if (this.deliveryMode === 'depot') {
          data.pickup_date = this.deliveryDate
        } else {
          data.delivery_date = this.deliveryDate
        }

        await this.orderCart(data)
        this.loading = false
        this.$router.push({ name: 'OrderSuccess' })
      } catch (error) {
        this.loading = false
        if (error.name === 'ValidationError') {
          this.$notyf.error(error.message)
          this.errors = error.errors
        } else {
          this.$router.push({ name: 'OrderFail' })
        }
      }
    },
    formatDate (date) {
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    },
    makeSections (categories) {
      const sections = {}
      for (const category of categories) {
        const products = []
        for (const productId of category.products) {
          let product = null
          for (const key in this.cart.products) {
            if (key === productId) {
              product = this.cart.products[key]
            }
          }
          if (product) {
            products.push(product)
          }
        }

        sections[category.id] = {
          id: category.id,
          name: category.name,
          products: products,
          total: category.total
        }
      }

      return Object.values(sections).sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    clear () {
      this.clearCart()
    },
    cancel () {
      this.$router.push({ name: 'Catalog' })
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>

hr {
  height:1px;
  background-color: "D0DADA";
  width: 100%;
}

div.recapContainer {
  display: inline-flex;
  flex:1;
  flex-direction: column;
  align-items:flex-end;
}

</style>
