<template>
  <svg
    :class="cssClass"
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="17"
  ><path
    fill="#00884B"
    d="M8.618 16.792.222 8.396 8.618 0 11 2.383 4.986 8.396 11 14.409z"
  /></svg>
</template>

<script>
export default {
  props: {
    cssClass: {
      type: String,
      default: null
    }
  }
}
</script>
