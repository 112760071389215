import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Catalog from '../views/Catalog.vue'
import Cart from '../views/Cart.vue'
import OrderSuccess from '../views/OrderSuccess.vue'
import OrderFail from '../views/OrderFail.vue'
import OrderHistory from '../views/OrderHistory.vue'
import OrderDetail from '../views/OrderDetail.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CatalogLayout from '@/layouts/CatalogLayout.vue'
import { createAuthGuard, createRestoreStoreGuard } from './guards'

const routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        name: 'Login',
        path: '',
        component: Login
      }
    ]
  },
  {
    path: '/catalog/:category?',
    component: CatalogLayout,
    children: [
      {
        name: 'Catalog',
        path: '',
        component: Catalog,
        props: route => {
          let page = parseInt(route.query.page)
          if (!page || page < 1) {
            page = 1
          }
          return { page: page }
        }
      }
    ],
    meta: {
      requiresAuth: true,
      needDataFetch: true
    }
  },
  {
    path: '/cart',
    component: DefaultLayout,
    children: [
      {
        name: 'Cart',
        path: '',
        component: Cart
      }
    ],
    meta: {
      requiresAuth: true,
      needDataFetch: true
    }
  },
  {
    path: '/order-success',
    component: DefaultLayout,
    children: [
      {
        name: 'OrderSuccess',
        path: '',
        component: OrderSuccess
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-fail',
    component: DefaultLayout,
    children: [
      {
        name: 'OrderFail',
        path: '',
        component: OrderFail
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-orders',
    component: DefaultLayout,
    children: [
      {
        name: 'OrderHistory',
        path: '',
        component: OrderHistory
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-orders/:orderId',
    component: DefaultLayout,
    children: [
      {
        name: 'OrderDetail',
        path: '',
        component: OrderDetail
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

export default function (store) {
  const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
      if (to.hash === '#no-scroll') {
        return savedPosition
      }
      // always scroll to top
      return { top: 0 }
    },
    routes
  })

  router.beforeEach(createRestoreStoreGuard(store))
  router.beforeEach(createAuthGuard(store))

  return router
}
