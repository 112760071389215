<template>
  <Header class="header--noBorder" />
  <div class="container">
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/misc/Header.vue'

export default {
  name: 'CatalogLayout',
  components: {
    Header
  }
}
</script>
